<template>
  <nav :class="this.class">
    <div class="container-fluid px-0">
      <router-link class="navbar-brand" :to="{name: 'Home'}" :title="isPageAdmin ? 'Erobo' : school.name">
        <img :src="(isPageAdmin || isPageAgency) ? '/assets/images/brand/logo/erobo.jpg' : (school.thumbnail ? school.thumbnail.link : '/assets/images/avatar/avatar.jpg')" class="avatar-sm object-fit-cover" :alt="isPageAdmin ? 'Erobo' : school.name"
        />
      </router-link>
      <ul
          class="navbar-nav navbar-right-wrap ms-auto d-lg-none d-flex nav-top-wrap"
      >
        <li class="dropdown ms-2" v-if="user">
          <a
              class="rounded-circle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
          >
            <div class="avatar avatar-md avatar-indicators avatar-online">
              <img
                  alt="avatar"
                  :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                  class="rounded-circle"
              />
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end shadow">
            <div class="dropdown-item">
              <div class="d-flex">
                <div class="avatar avatar-md avatar-indicators avatar-online">
                  <img
                      alt="avatar"
                      :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                      class="rounded-circle"
                  />
                </div>
                <div class="ms-3 lh-1">
                  <h5 class="mb-1">{{ user.name }}</h5>
                  <p class="mb-0 text-muted">{{ user.email ? user.email : user.phone }}</p>
                </div>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <ul class="list-unstyled">
              <li>
                <router-link class="dropdown-item" :to="{name: 'MyAccount'}">
                  <i class="fe fe-user me-2"></i>Tài khoản của tôi
                </router-link>
              </li>
            </ul>
            <div class="dropdown-divider"></div>
            <ul class="list-unstyled">
              <router-link class="dropdown-item" :to="{name: 'Logout'}">
                <i class="fe fe-power me-2"></i>Đăng xuất
              </router-link>
            </ul>
          </div>
        </li>
      </ul>
      <!-- Button -->
      <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <span class="icon-bar top-bar mt-0"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </button>
      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbar-default">
        <ul class="navbar-nav" v-if="user">
          <li class="nav-item dropdown" v-if="(isPageAgency || isPageAdmin) && (user.role === 'admin' || user.agency_role)">
            <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarAgencyMobile"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-display="static"
            >
              Đại lý
            </a>
            <ul
                class="dropdown-menu dropdown-menu-arrow"
                aria-labelledby="navbarAgencyMobile"
            >
              <li v-if="user.agency_role">
                <router-link class="dropdown-item" :to="{name: 'MyAgency'}">
                  Đại lý của tôi
                </router-link>
              </li>
              <li v-if="isPageAdmin && user.role === 'admin'">
                <router-link class="dropdown-item" :to="{name: 'AdminAgencyList'}">
                  Quản lý đại lý
                </router-link>
              </li>
              <li v-if="isPageAdmin && user.role === 'admin'">
                <router-link class="dropdown-item" :to="{name: 'AdminAgencyAdd'}">
                  Thêm đại lý
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="(!isPageAgency || isPageAdmin) && (user.role === 'admin' || user.school_role === 'admin' || user.school_role === 'teacher')">
            <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarBrowseMobile"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-display="static"
            >
              Trường học
            </a>
            <ul
                class="dropdown-menu dropdown-menu-arrow"
                aria-labelledby="navbarBrowseMobile"
            >
              <li v-if="user.school_role === 'admin' || user.school_role === 'teacher'">
                <router-link class="dropdown-item" :to="{name: 'MySchool'}">
                  Trường học của tôi
                </router-link>
              </li>
              <li v-if="isPageAdmin && user.role === 'admin'">
                <router-link class="dropdown-item" :to="{name: 'AdminListSchool'}">
                  Quản lý trường học
                </router-link>
              </li>
              <li v-if="isPageAdmin && user.role === 'admin'">
                <router-link class="dropdown-item" :to="{name: 'AdminAddSchool'}">
                  Thêm trường học
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="isPageAdmin && user.role === 'admin'">
            <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarBrowse"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-display="static"
            >
              Người dùng
            </a>
            <ul
                class="dropdown-menu dropdown-menu-arrow"
                aria-labelledby="navbarBrowse"
            >
              <li>
                <router-link class="dropdown-item" :to="{name: 'AdminListUser'}">
                  Quản lý người dùng
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'AdminAddUser'}">
                  Thêm người dùng
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <form class="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
				<span class="position-absolute ps-3 search-icon">
					<i class="fe fe-search"></i>
				</span>
          <input
              type="search"
              class="form-control ps-6"
              placeholder="Tìm kiếm"
          />
        </form>
        <div class="ms-auto mt-3 mt-lg-0 navbar-nav d-flex align-items-center" v-if="!user">
          <li class="nav-item me-4" v-if="!isPageAdmin">
            <router-link :to="{name: 'PageList'}" class="nav-link">
              Tin tức
            </router-link>
          </li>
          <router-link :to="{name: 'Login'}" class="btn btn-white shadow-sm me-1">
            Đăng nhập
          </router-link>
          <router-link :to="{name: 'Register'}" class="btn btn-primary">
            Đăng ký
          </router-link>
        </div>
        <ul class="navbar-nav navbar-right-wrap ms-auto d-none d-lg-block" v-if="user">
          <li class="nav-item me-4 d-inline-block" v-if="!isPageAdmin && !isPageAgency">
            <router-link :to="{name: 'PageList'}" class="nav-link">
              Tin tức
            </router-link>
          </li>
          <li class="dropdown stopevent d-inline-block">
            <a
                :class="unread > 0 ? 'btn btn-light btn-icon rounded-circle text-muted indicator indicator-primary' : 'btn btn-light btn-icon rounded-circle text-muted indicator'"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
            >
              <i class="fe fe-bell"> </i>
            </a>
            <div class="dropdown-menu dropdown-menu-end shadow dropdown-menu-lg">
              <div>
                <div
                    class="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center"
                >
                  <span class="h5 mb-0">Thông báo</span>
                  <a href="javascript:void(0)"
                     v-on:click="markAsReadAll"
                     class="text-muted badge-dot bg-info"
                     data-bs-toggle="tooltip"
                     data-bs-placement="top"
                     title="Đánh dấu tất cả đã đọc"
                  ></a>
                </div>
                <ul class="list-group list-group-flush" v-if="!notifications.data || notifications.data.length === 0">
                  <li class="list-group-item">Bạn chưa có thông báo nào</li>
                </ul>
                <scrollbar v-if="notifications.data && notifications.data.length">
                  <ul class="list-group list-group-flush notification-list-scroll">
                    <li :class="notification.is_read === 1 ? 'list-group-item' : 'list-group-item bg-light'" :key="'notification' + index" v-for="(notification, index) in notifications.data">
                      <div class="row">
                        <div class="col">
                          <a href="javascript:void(0)" class="text-body" v-on:click="readNotification(notification)">
                            <div class="d-flex">
                              <img
                                  :src="notification.sender && notification.sender.thumbnail ? notification.sender.thumbnail.link : '/assets/images/avatar/avatar-1.jpg'"
                                  alt=""
                                  class="avatar-md rounded-circle"
                              />
                              <div class="ms-3">
                                <h5 class="fw-bold mb-1">{{ notification.sender ? notification.sender.name : '' }}</h5>
                                <p class="mb-3">
                                  {{ notification.content }}
                                </p>
                                <span class="fs-6 text-muted">
													<span
                          ><span
                              class="fe fe-thumbs-up text-success me-1"
                          ></span
                          >{{ timeago(notification.created_at) }}</span
                          >
												</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-auto text-center me-2" v-if="!notification.is_read">
                          <a
                              href="javascript:void(0)"
                              v-on:click="markAsRead(notification)"
                              class="badge-dot bg-info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Đánh dấu đã đọc"
                          >
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </scrollbar>

                <div class="border-top px-3 pt-3 pb-0" v-if="notifications.data && notifications.data.length">
                  <router-link :to="{name: 'Notification'}" class="text-link fw-semi-bold">
                    Xem tất cả
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="dropdown ms-2 d-inline-block">
            <a
                class="rounded-circle"
                href="#"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
            >
              <div class="avatar avatar-md avatar-indicators avatar-online">
                <img
                    alt="avatar"
                    :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                    class="rounded-circle"
                />
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="dropdown-item">
                <div class="d-flex">
                  <div class="avatar avatar-md avatar-indicators avatar-online">
                    <img
                        alt="avatar"
                        :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                        class="rounded-circle"
                    />
                  </div>
                  <div class="ms-3 lh-1">
                    <h5 class="mb-1">{{ user.name }}</h5>
                    <p class="mb-0 text-muted">{{ user.email ? user.email : user.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider"></div>
              <ul class="list-unstyled">
                <li>
                  <router-link class="dropdown-item" :to="{name: 'MyAccount'}"><i class="fe fe-user me-2"></i>Tài khoản của tôi</router-link>
                </li>
              </ul>
              <div class="dropdown-divider"></div>
              <ul class="list-unstyled">
                <li>
                  <router-link class="dropdown-item" :to="{name: 'Logout'}">
                    <i class="fe fe-power me-2"></i>Đăng xuất
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

import ApiService from "@/core/services/api.service";
import {isPageAdmin, isPageAgency, timeago} from "@/core/services/utils.service";
import Scrollbar from "vue3-smooth-scrollbar";

export default {
  name: 'Navbar',
  components: {
    Scrollbar
  },
  props: {
    class: String,
    school: Object,
    user: Object
  },
  data() {
    return {
      unread: 0,
      notifications: {
        data: []
      }
    }
  },
  methods: {
    loadNotifications() {
      let query = `query {
        me {
          id
          unread
          notifications(first:10, orderBy: [{column: "id", order: DESC}]) {
            data {
              code
              content
              extra
              is_read
              created_at
              sender {
                name
                thumbnail {
                  link
                }
              }
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.me) {
              this.unread = data.data.me.unread;
              this.notifications = data.data.me.notifications;
            }
          });
    },
    markAsRead(notification) {
      let query = `mutation($code: String!) {
        markAsRead(code: $code)
      }`;

      ApiService.graphql(query, {code: notification.code})
          .then(({data}) => {
            if (data.data && data.data.markAsRead) {
              this.loadNotifications();
            } else {
              notification.is_read = 0;
            }
          });
    },
    markAsReadAll() {
      let query = `mutation {
        markAsReadAll
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.markAsReadAll) {
              this.loadNotifications();
            }
          });
    },
    readNotification(notification) {
      if (!notification.is_read) {
        notification.is_read = 1;
        this.markAsRead(notification);
      }
      if (notification.extra) {
        try {
          let extra = JSON.parse(notification.extra);
          if (extra.router && extra.router.name && extra.router.params) {
            this.$router.push({name: extra.router.name, params: extra.router.params});
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    timeago(x) {
      return timeago(x);
    }
  },
  mounted() {
    this.loadNotifications();
  },
  computed: {
    isPageAdmin() {
      return isPageAdmin();
    },
    isPageAgency() {
      return isPageAgency();
    }
  }
}
</script>
