<template>
  <Navbar class="navbar navbar-expand-lg navbar-default" :school="school" :user="user"/>
  <router-view :user="user"/>
  <Footer/>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: 'Layout',
  props: {
    school: Object,
    user: Object
  },
  components: {
    Navbar,
    Footer
  },
}
</script>
